import React, { ChangeEvent, Fragment, useState } from 'react';
import Icon from 'react-fontawesome';
import TimeField from 'react-simple-timefield';
import { TaskForm } from '../../../../app/models/Task';
import moment from 'moment';
import { Checkbox, RunsListSearch, VehicleSelectForTask, YesNoRadio } from '../../../common/inputs';
import { FormControl, Row, InputGroup, Col, Button, FormGroup } from 'react-bootstrap';
import { Form, FieldGroup, Callout, UserDetailsBox, Tooltip, ErrorBox } from '../../../common/uiElements';
import { PlacesList, RunTypesList, PlacesListNew } from '../../../common/lists';
import { IVehicleBasicInfo, IVehicleDetailsForTask, VehicleTypes } from '../../../../app/models/Vehicle';
import { getVehicleServiceOverlappingTask, getVehicleExceedsStoreMaxPalletCapacity, getStoreRequiresTailgateIssue } from '../tasks/tasksHelper';
import { ItemInterface, ReactSortable, Store } from "react-sortablejs";
import { uniq } from 'lodash';
import { IError, IReactSelectReturn } from '../../../../app/models/Application';
import { IPlacesListItem } from '../../../../app/models/Place';
import FontAwesome from 'react-fontawesome';
import { IListRunTypeItem } from '../../../../app/models/List';
import { IRun } from '../../../../app/models/Run/Run';
import RunApi from '../../../../app/api/RunApi';

interface IProps extends TaskForm {
	formId?: string;
	isEditMode?: boolean;
	isReadOnly?: boolean;
	isChangeDateEnabled?: boolean;
	isChangePlaceEnabled?: boolean;
	hideDate?: boolean;
	hideStartingDepot?: boolean;
	hideTimeFields?: boolean;
	hideRunCustomerFields?: boolean;
	showMainDriver?: boolean;
	onChangeInput?: (id: string, value: string | boolean) => void;
	onChangeVehicle?: (fieldId: string, vehicle?: IVehicleBasicInfo) => void;
	onCheckMainVehicleNotTracked?: (isNotTracked: boolean) => void;
	onCheckTrailer1NotTracked?: (isNotTracked: boolean) => void;
	onCheckTrailer2NotTracked?: (isNotTracked: boolean) => void;
	onChangeStartTime?: (startTime: string) => void;
	onChangeRunCustomerRunNumber?: (event: ChangeEvent<FormControl & HTMLInputElement>, runCustomerIndex: number) => void;
	onChangeRunCustomerRunType?: (runCustomerIndex: number, runType?: IListRunTypeItem) => void;
	onClickAddNewRunCustomer?: () => void;
	onClickRemoveRunCustomer?: (runCustomerIndex: number) => void;
	onChangeRunCustomerOrder?: (runCustomerOrder: IReactSelectReturn, runCustomerOrderIndex: number) => void;
	onClickAddNewRunCustomerOrder?: () => void;
	onClickRemoveRunCustomerOrder?: (runCustomerOrderIndex: number) => void;
	onChangeSequenceRunCustomerOrder?: (RunCustomerOrders: ItemInterface[], sortable: any, store: Store) => void;
	
	// Shuttle
	onClickAddNewRunShuttleTaskLink: (vehicleSection: number) => void;
	onClickRemoveRunShuttleTaskLink: (vehicleSection: number, runShuttleTaskLinkIndex: number) => void;
	onChangeRunShuttleTaskLink: (vehicleSection: number, runShuttleTaskLinkIndex: number, runId?: string) => void;
	onClearRunShuttleTaskLinks: (vehicleSection: number, shouldAddEmptyLink: boolean) => void;
}

const validations = {
	date: 'required',
	startTime: 'required',
	startingDepotId: 'required',
	budgetedTime: 'required',
	area: 'required',
	runTypeId: 'required',
	runNumber: 'required',
	runCustomerOrders: 'required'
}

const messages = {
	runCustomerOrders: {
		required: 'You must include at least one Store or Destination'
	}
}

const RunForm: React.FC<IProps> = props => {
	const [runDetailsTrailer1, setRunDetailsTrailer1] = useState<IRun | undefined>(undefined);
	const [isLoadingRunDetailsTrailer1, setIsLoadingRunDetailsTrailer1] = useState(false);
	const [errorLoadingRunDetailsTrailer1, setErrorLoadingRunDetailsTrailer1] = useState<IError | undefined>(undefined)

	const [runDetailsTrailer2, setRunDetailsTrailer2] = useState<IRun | undefined>(undefined);
	const [isLoadingRunDetailsTrailer2, setIsLoadingRunDetailsTrailer2] = useState(false);
	const [errorLoadingRunDetailsTrailer2, setErrorLoadingRunDetailsTrailer2] = useState<IError | undefined>(undefined);

	const startDateTime = props.date + ' ' + props.startTime;

	// Check if the task is an external shuttle task
	const isShuttleTask = props.RunCustomers?.some(p => p.runTypeIsShuttle);

	const fetchRunDetailsTrailer1 = (runId?: number) => {
		setRunDetailsTrailer1(undefined);
		if (!runId)
			return;

		setIsLoadingRunDetailsTrailer1(true);
		RunApi.getRunDetails(runId)
			.then(run => {
				setRunDetailsTrailer1(run);
				if (!run?.trailer2FleetNumber && run?.trailer1FleetNumber)
					props.onChangeInput && props.onChangeInput('trailer1FleetNumber', run?.trailer1FleetNumber);
			})
			.catch(setErrorLoadingRunDetailsTrailer1)
			.finally(() => setIsLoadingRunDetailsTrailer1(false))
			
	}

	// eslint-disable-next-line
	const fetchRunDetailsTrailer2 = (runId?: number) => {
		setRunDetailsTrailer2(undefined);
		if (!runId)
			return;

		setIsLoadingRunDetailsTrailer2(true);
		RunApi.getRunDetails(runId)
				.then(run => {
					setRunDetailsTrailer2(run);
					if (!run?.trailer2FleetNumber && run?.trailer1FleetNumber)
						props.onChangeInput && props.onChangeInput('trailer2FleetNumber', run?.trailer1FleetNumber);
				})
			.catch(setErrorLoadingRunDetailsTrailer2)
			.finally(() => setIsLoadingRunDetailsTrailer2(false))
	}

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value, type, checked } = e.target;
		props.onChangeInput && props.onChangeInput(id, type === 'checkbox' ? checked : value);
	}

	const handleCheckMainVehicleNotTracked = (e: ChangeEvent<HTMLInputElement>) => {
		props.onCheckMainVehicleNotTracked && props.onCheckMainVehicleNotTracked(e.target.checked);
	}

	const handleCheckTrailer1NotTracked = (e: ChangeEvent<HTMLInputElement>) => {
		props.onCheckTrailer1NotTracked && props.onCheckTrailer1NotTracked(e.target.checked);
	}

	// const handleCheckTrailer2NotTracked = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
	// 	props.onCheckTrailer2NotTracked && props.onCheckTrailer2NotTracked(e.target.checked);
	// }

	const getTrailersFromTrailer1Run = () => {
		const selectOptions = [];

		if (runDetailsTrailer1) {
			if (runDetailsTrailer1.trailer1FleetNumber)
				selectOptions.push(runDetailsTrailer1.trailer1FleetNumber)

			if (runDetailsTrailer1.trailer2FleetNumber)
				selectOptions.push(runDetailsTrailer1.trailer2FleetNumber)
		}

		return selectOptions;
	}

	const getTrailersFromTrailer2Run = () => {
		const selectOptions = [];

		if (runDetailsTrailer2) {
			if (runDetailsTrailer2.trailer1FleetNumber)
				selectOptions.push(runDetailsTrailer2.trailer1FleetNumber)

			if (runDetailsTrailer2.trailer2FleetNumber)
				selectOptions.push(runDetailsTrailer2.trailer2FleetNumber)
		}

		return selectOptions;
	};

	const renderRunCustomers = () => {
		if (!props.RunCustomers)
			return null;

		if (props.isReadOnly || props.isEditMode)
			return (
				<FormGroup>
					<b style={{ fontSize: '25px' }}>
						{ props.RunCustomers?.map(({ runTypeAcronym, runNumber }) => runTypeAcronym! + runNumber!).join(', ') }
					</b>
				</FormGroup>
			)
		
		return (
			<>
				{
					props.RunCustomers.map((runCustomer, index) => (
						<Row key={index}>
							<Col xs={5}>
								<FormGroup>
									<RunTypesList 
										id="runTypeId"
										firstOptionText="--- CUSTOMER ---"
										taskTypeId={props.taskTypeId}
										value={runCustomer.runTypeId}
										bsSize="small"
										className="required"
										onChange={(e, runTypeName, runType) => props.onChangeRunCustomerRunType && props.onChangeRunCustomerRunType(index, runType)}
									/>
								</FormGroup>
							</Col>
							<Col xs={5}>
								<FormGroup>
									<FormControl 
										id="runNumber"
										type="number"
										value={runCustomer.runNumber}
										placeholder="Run Number"
										className="required"
										bsSize="small"
										onChange={(e: any) => props.onChangeRunCustomerRunNumber && props.onChangeRunCustomerRunNumber(e, index)}
									/>
								</FormGroup>
							</Col>
							<Col xs={2}>
								{ 
									index > 0 && (
										<Button
											block
											bsStyle="danger"
											bsSize="small"
											onClick={() => props.onClickRemoveRunCustomer && props.onClickRemoveRunCustomer(index)}
										>
											<Icon name="times" />
										</Button>
									)
								}
							</Col>
						</Row>
					))
				}
				{
					// If its not cancelled, not edit mode, and can have multiple runs (which means its a delivery to store type of task, not shuttle)
					!props.isReadOnly && !props.isEditMode && props.TaskType?.canHaveMultipleRuns && (
						<FormGroup>
							<Button 
									bsSize="small"
									bsStyle="success"
									onClick={props.onClickAddNewRunCustomer}
								>
									<Icon name="plus" /> Add Customer
							</Button>
						</FormGroup>
					)
				}
				<hr />
			</>
		)
	}

	const renderLinkedRunsContent = (vehicleSection: number, fleetNumber: string) => {
		const RunShuttleTaskLinksFiltered = props.RunShuttleTaskLinks.filter(link => link.vehicleSection === vehicleSection);

		// if (RunShuttleTaskLinksFiltered.length === 0)
		// 	return null;
		
		// Check if the Run is an empty trailer instead
		const isEmptyTrailer = RunShuttleTaskLinksFiltered[0]?.Run_id === "";

		return (
			<>
				<div className="text-center">
					<FontAwesome name="arrow-down" />
				</div>
				{
					RunShuttleTaskLinksFiltered.map((link, index) => {
						return (
							<FormGroup key={index}>
								<RunsListSearch
									showArea
									disabled={props.isReadOnly}
									value={link.Run_id}
									date={props.date}
									emptyOptionText={index === 0 ? "Empty Trailer" : undefined}
									onChange={run => props.onChangeRunShuttleTaskLink(vehicleSection, index, run?.id?.toString())}
								/>
								<Button block bsSize="xs" bsStyle="danger" onClick={() => props.onClickRemoveRunShuttleTaskLink(vehicleSection, index)}>
									<FontAwesome name="arrow-up" />	Remove Run
								</Button>
								<FormControl 
									required
									id={`trailer${vehicleSection}_Run_id_${index}`}
									type="hidden" 
									value={(index === 0 && link.Run_id !== undefined) || link.Run_id ? 'OK' : ''}
								/>
							</FormGroup>
						)
					})
				}
				{
					(
						// only show the add new run button if the trailer is not an empty trailer
						!isEmptyTrailer 
						// Or if no vehicle is selected
						|| !fleetNumber
					) && (
						<FormGroup>
							<Button 
								block
								bsSize="small"
								bsStyle="success"
								onClick={() => props.onClickAddNewRunShuttleTaskLink(vehicleSection)}
							>
									<Icon name="plus" /> Add Run
							</Button>
						</FormGroup>
					)
				}
			</>
		)
	}

	const renderRunDetails = () => (
		<>
			<Row>
				{
					!props.hideDate && (
						<Fragment>
							<FieldGroup label="Date" sm={4} required>
								{
									props.isChangeDateEnabled && !props.isReadOnly ? (
										<FormControl
											id="date"
											type="date"
											value={props.date || ''}
											onChange={handleChangeInput}
										/>
									) : (
										<p>{ moment(props.date).format('DD/MM/YYYY') }</p>
									)
								}
							</FieldGroup>
							<FieldGroup label="Day of Week" sm={4}>
								<p>{ moment(props.date).isValid() ? moment(props.date).format('dddd') : '-' }</p>
							</FieldGroup>
						</Fragment>
					)
				}
				{
					!props.hideStartingDepot && (
						<FieldGroup label="Starting Depot" sm={4} required>
							{
								props.isChangePlaceEnabled && !props.isReadOnly ? (
									<PlacesList
										id="startingDepotId"
										type="rosterStartingDepot"
										value={props.startingDepotId || ''}
										onChange={handleChangeInput}
									/>
								) : (
									<p>{ props.startingDepotName }</p>
								)
							}
						</FieldGroup>
					)
				}
			</Row>
			{
				!props.hideTimeFields && (
					<Row>
						<FieldGroup label="Start Time" sm={4} required>
							{
								!props.isReadOnly ? (
									<TimeField
										width={4}
										value={props.startTime || '__:__'} 
										input={<FormControl id="startTime" />} 
										onChange={props.onChangeStartTime}
									/>
								) : (
									<p>{ moment(props.startTimeLocal).format('HH:mm') }</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Budgeted Time" sm={4} required>
							{
								!props.isReadOnly ? (
									<InputGroup>
										<FormControl
											id="budgetedTime"
											type="number"
											max={20}
											min={0.25}
											value={props.budgetedTime || ''}
											onChange={handleChangeInput}
										/>
										<InputGroup.Addon>
											HOURS
										</InputGroup.Addon>
									</InputGroup>
								) : (
									<p>{ props.budgetedTime } h</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Finish Time" sm={4}>
							<p>
							{ moment(startDateTime).isValid() && props.budgetedTime ? moment(startDateTime).add(props.budgetedTime, 'hours').format('HH:mm') : '-' }
							</p>
						</FieldGroup>
					</Row>
				)
			}
			{
				props.showMainDriver && (
					<FieldGroup label="Main Driver">
						{
							props.workerId ? (
								<UserDetailsBox azureId={props.workerId} workerData={props.Worker} />
							) : <p><i>No Driver Allocated</i></p>
						}
					</FieldGroup>
				)
			}
			<Row>
				<FieldGroup label="Destination / Area" sm={4} required>
					{/* Can't change area at this stage */}
					{
						!props.isReadOnly && !props.isEditMode ? (
							<FormControl 
								id="area"
								value={props.area || ''}
								onChange={handleChangeInput}
							/>
						) : (
							<p>{ props.area || '-' }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Is Pre-Loaded?" sm={4}>
					{
						!props.isReadOnly ? (
							<YesNoRadio 
									id="isPreLoaded"
									value={props.isPreLoaded || false}
									onChange={handleChangeInput} 
								/>
						) : (
							<p>{ props.isPreLoaded ? 'YES' : 'NO' }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Budgeted Km" sm={4}>
					{
						!props.isReadOnly ? (
							<InputGroup>
								<FormControl
									id="budgetedKm"
									value={props.budgetedKm || ''}
									onChange={handleChangeInput}
								/>
								<InputGroup.Addon>
									KM
								</InputGroup.Addon>
							</InputGroup>
						) : (
							<p>{ props.budgetedKm ? props.budgetedKm + ' KM' : '-' }</p>
						)
					}
				</FieldGroup>
			</Row>
			<Row>
				<FieldGroup label="Main Vehicle" sm={4}>
					{
						!props.isReadOnly && props.subContractorAbn !== undefined && (
							<Checkbox 
								id="isMainVehicleNotTracked"
								text="Not Tracked" 
								checked={props.isMainVehicleNotTracked || false} 
								onChange={handleCheckMainVehicleNotTracked}
							>
								&nbsp;
								<Tooltip 
									text="If checked, the 'Main Vehicle' will not be tracked by Micway for this task and it won't be marked as 'No Vehicle Assigned' in the system and reports."
								/>
							</Checkbox>
						)
					}
					{
						props.isReadOnly ? (
							<p>{ props.mainVehFleetNumber || (props.isMainVehicleNotTracked ? <i>Not Tracked By Micway</i> : <i>None</i>) }</p>
						) : (
							!props.isMainVehicleNotTracked && (
								<>
									<VehicleSelectForTask
										id="mainVehFleetNumber"
										checkAvailabilityDate={props.date + ' ' + props.startTime}
										placeholder="Select Main Veh."
										value={props.mainVehFleetNumber || ''}
										vehTypeFilter={[VehicleTypes.PRIME_MOVER, VehicleTypes.RIGID]} 
										onChange={vehicle => props.onChangeVehicle && props.onChangeVehicle('mainVehFleetNumber', vehicle)}
									/>
									{ props.date && props.startTime && props.budgetedTime && renderVehicleMaintenanceAlert(props.date + ' ' + props.startTime, parseFloat(props.budgetedTime), props.MainVehicle) }
									{
										isShuttleTask && renderLinkedRunsContent(0, props.mainVehFleetNumber)
									}
								</>
							)
						)
					}
				</FieldGroup>
				{
					(!props.MainVehicle || props.MainVehicle.typeId === VehicleTypes.PRIME_MOVER) && (
						<FieldGroup label="Trailer 1" sm={4}>
							{
								!props.isReadOnly && props.subContractorAbn !== undefined && (
									<Checkbox 
										id="isTrailer1NotTracked"
										text="Not Tracked" 
										checked={props.isTrailer1NotTracked || false} 
										onChange={handleCheckTrailer1NotTracked}
									>
										&nbsp;
										<Tooltip 
											text="If checked, the 'Trailer 1' will not be tracked by Micway for this task and it won't be marked as 'No Vehicle Assigned' in the system and reports."
										/>
									</Checkbox>
								)
							}
							{
								props.isReadOnly ? (
									<p>{ props.trailer1FleetNumber || (props.isTrailer1NotTracked ? <i>Not Tracked By Micway</i> : <i>None</i>) }</p>
								) : (
									props.isTrailer1NotTracked ? null : (
										errorLoadingRunDetailsTrailer1 ? (
											<ErrorBox error={errorLoadingRunDetailsTrailer1} retryFunc={fetchRunDetailsTrailer1} />
										) : (
											<>
												<VehicleSelectForTask
													id="trailer1FleetNumber"
													placeholder="Select Trailer 1..."
													checkAvailabilityDate={props.date + ' ' + props.startTime}
													isLoading={isLoadingRunDetailsTrailer1}
													value={props.trailer1FleetNumber || ''}
													vehTypeFilter={[VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE]} 
													excludeFleetNumbers={props.trailer2FleetNumber ? [props.trailer2FleetNumber] : []}
													includeFleetNumbers={isShuttleTask ? getTrailersFromTrailer1Run() : []}
													onChange={vehicle => props.onChangeVehicle && props.onChangeVehicle('trailer1FleetNumber', vehicle)}
												/>
												{ props.date && props.startTime && props.budgetedTime && renderVehicleMaintenanceAlert(props.date + ' ' + props.startTime, parseFloat(props.budgetedTime), props.Trailer1) }
											</>
										)
									)
								)
							}
							{ isShuttleTask && renderLinkedRunsContent(1, props.trailer1FleetNumber) }
						</FieldGroup>
					)
				}
				{
					(
						// If the Trailer 1 is selected and it's a B-Double
						(props.Trailer1 && props.Trailer1.typeId === VehicleTypes.B_DOUBLE) 
						// Or if there's a run or empty trailer linked to Trailer 2
						|| (props.RunShuttleTaskLinks && props.RunShuttleTaskLinks.filter(link => link.vehicleSection === 2).length > 0)
					)
					&& (
						<FieldGroup label="Trailer 2" sm={4}>
							{
								props.isReadOnly ? (
									<p>{ props.trailer2FleetNumber || <i>None</i> }</p>
								) : (
									isShuttleTask && errorLoadingRunDetailsTrailer2 ? (
										<ErrorBox error={errorLoadingRunDetailsTrailer2} retryFunc={fetchRunDetailsTrailer1} />
									) : (
										<>
											<VehicleSelectForTask
												id="trailer2FleetNumber"
												placeholder="Select Trailer 2..."
												checkAvailabilityDate={props.date + ' ' + props.startTime}
												value={props.trailer2FleetNumber || ''}
												isLoading={isLoadingRunDetailsTrailer2}
												vehTypeFilter={[VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE]}
												excludeFleetNumbers={props.trailer1FleetNumber ? [props.trailer1FleetNumber] : []}
												includeFleetNumbers={isShuttleTask ? getTrailersFromTrailer2Run() : []}
												onChange={vehicle => props.onChangeVehicle && props.onChangeVehicle('trailer2FleetNumber', vehicle)}
											/>
											{ props.date && props.startTime && props.budgetedTime && renderVehicleMaintenanceAlert(props.date + ' ' + props.startTime, parseFloat(props.budgetedTime), props.Trailer2) }
										</>
									)
								)
							}
							{ isShuttleTask && renderLinkedRunsContent(2, props.trailer2FleetNumber) }
						</FieldGroup>
					)
				}
			</Row>
		</>
	)

	const renderDeliverySequence = () => (
		<>
			<h3 className="title">Delivery Sequence</h3>
			{
				!props.RunCustomers || props.RunCustomers.filter(p => p.runTypeId).length === 0 ? (
					<Callout text="Select at least one Run Type to proceed" color="info" />
				) : (
					<>
						<Row>
							<Col xs={2}>
								{
									props.RunCustomerOrders?.map((order, index) => (
										<Fragment key={index}>
											<FormGroup className="sortable-list-index">
												{ index + 1 }
											</FormGroup>
											{
												getVehicleExceedsStoreMaxPalletCapacity(order.storeMaxPalletCapacity, props.MainVehicle, props.Trailer1, props.Trailer2) && (
													<div style={{ height: 116 }} />
												)
											}
											{
												getStoreRequiresTailgateIssue(order.storeRequiresTailgate, props.MainVehicle, props.Trailer1, props.Trailer2) && (
													<div style={{ height: 84 }} />
												)
											}
										</Fragment>
									))
								}
							</Col>
							<Col xs={10}>
								<ReactSortable className="sortable-list" list={props.RunCustomerOrders as any || []} setList={props.onChangeSequenceRunCustomerOrder}>
									{
										props.RunCustomerOrders?.map((order, index) => {
											const vehicleExceededStoreMaxPalletCapacity = getVehicleExceedsStoreMaxPalletCapacity(order.storeMaxPalletCapacity, props.MainVehicle, props.Trailer1, props.Trailer2)
											const storeRequiresTailgateIssue = getStoreRequiresTailgateIssue(order.storeRequiresTailgate, props.MainVehicle, props.Trailer1, props.Trailer2);
											return (
												<FormGroup key={index}>
													<InputGroup>
														{
															!props.isReadOnly && (
																<InputGroup.Addon>
																	<Icon name="arrows" />
																</InputGroup.Addon>
															)
														}
														{
															order.id ? (
																<FormControl 
																	readOnly
																	value={order.placeName + (order.storeNumber ? ` (${order.storeNumber})` : '')}
																/>
															) : (
																<PlacesListNew 
																	id="placeId"
																	type="deliveryStores"
																	runTypes={props.RunCustomers ? uniq(props.RunCustomers?.map(p => p.runTypeId!)) : undefined}
																	placeholder="Select Store or Destination..."
																	value={order.placeId}
																	onChange={(obj) => {
																		const store = obj.fullObject as IPlacesListItem;
																		order.storeRequiresTailgate = store.isTailgateRequired;
																		order.storeNumber = store.storeNumber;
																		order.placeName = store.name;
																		order.storeMaxPalletCapacity = store.maxPalletCapacity;
																		props.onChangeRunCustomerOrder && props.onChangeRunCustomerOrder(obj, index)
																	}}
																/>
															)
														}
														{
															order.id && (
																<InputGroup.Addon className="hidden-xs">
																	{
																		order.openWindow && order.closeWindow ? (
																			`${moment(order.openWindow).format('HH:mm')} - ${moment(order.closeWindow).format('HH:mm')}`
																		) : (
																			'Unknown'
																		)
																	}
																</InputGroup.Addon>
															)
														}
														{
															!props.isReadOnly && props.RunCustomerOrders && props.RunCustomerOrders.length > 1 && (
																<InputGroup.Button>
																	<Button
																		bsStyle="danger"
																		disabled={props.RunCustomerOrders?.length === 1}
																		onClick={() => props.onClickRemoveRunCustomerOrder && props.onClickRemoveRunCustomerOrder(index)}
																	>
																		<Icon name="times" />
																	</Button>
																</InputGroup.Button>
															)
														}
													</InputGroup>
													{
														vehicleExceededStoreMaxPalletCapacity && (
															<Callout style={{ marginTop: 3 }} icon="warning" title="Max Pallet Store Capacity">
																Confirm the Fleet No. <b>'{ vehicleExceededStoreMaxPalletCapacity.fleetNumber }'</b> before despatch as the store <b>'{ order.placeName }'</b> can accommodate a max of <b>'{ order.storeMaxPalletCapacity } pallets'</b>.
															</Callout>
														)
													}
													{
														storeRequiresTailgateIssue && (
															<Callout style={{ marginTop: 3 }} icon="warning" title="Tailgate Vehicle Required">
																This store requires a vehicle with tailgate. Please ensure the vehicle is equipped with a tailgate before despatch.
															</Callout>
														)
													}
												</FormGroup>
											)
										})
									}
								</ReactSortable>
							</Col>
						</Row>
						<FormControl 
							id="runCustomerOrders" 
							type="hidden" 
							value={props.RunCustomerOrders?.find(p => !p.placeId) ? '' : 'OK'} 
						/>
						{
							!props.isCancelled && !props.isReadOnly && (
								<FormGroup>
									<Button 
											bsSize="small"
											bsStyle="success"
											onClick={props.onClickAddNewRunCustomerOrder}
										>
											<Icon name="plus" /> Add Destination
									</Button>
								</FormGroup>
							)
						}
					</>
				)
			}
		</>
	)

	return (
		<Form id={props.formId} validations={validations} messages={messages}>
			{ !props.hideRunCustomerFields && renderRunCustomers() }
			{
				!props.RunCustomers || props.RunCustomers.filter(p => p.runTypeId).length === 0 ? (
					<Callout text="Select at least one Customer" color="info" />
				) : (
					<>
						{ renderRunDetails() }
						{ !props.hideRunCustomerFields && renderDeliverySequence() }
					</>
				)
			}
			
		</Form>
	)
}

export default RunForm;

function renderVehicleMaintenanceAlert(taskDate: string, taskBudgetedTime: number, Vehicle?: IVehicleDetailsForTask) {
	if (!Vehicle || !Vehicle.MaintenanceServices) 
		return;
	
	const serviceData = getVehicleServiceOverlappingTask(taskDate, taskBudgetedTime, Vehicle?.MaintenanceServices);
	if (!serviceData) 
		return;

	const { startTimeLocal, finishTimeLocal } = serviceData;

	return (
		<Callout title="Vehicle Booked for Service">
			This vehicle is <b>booked for service</b> from <b>{moment(startTimeLocal).format('DD/MM/YY HH:mm')}</b> to <b>{moment(finishTimeLocal).format('DD/MM/YY HH:mm')}</b>.
			Please check its availability.
		</Callout>
	)
}