import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';

const propTypes = {
	label: PropTypes.shape({
		text: PropTypes.string,
		fontSize: PropTypes.string,
		color: PropTypes.string
	}),
	icon: PropTypes.shape({
		url: PropTypes.string,
		size: PropTypes.object
	}),
	position: PropTypes.object.isRequired,
	labelOrigin: PropTypes.object,
	zIndex: PropTypes.number,
};

const GoogleMarker = props => {
	let markerProps = { ...props };

	markerProps.label = {
		text: markerProps.label?.text || markerProps.label,
		fontSize: markerProps.label?.fontSize || "12px",
		color: markerProps.label?.color,
		fontWeight: markerProps.label?.fontWeight || "bold"
	}

	markerProps.icon = {
		url: markerProps.icon?.url || 'http://cdn.micway.com.au/tms/img/map_marker.png',
		labelOrigin: markerProps.labelOrigin || new window.google.maps.Point(20, 45),
		scaledSize: markerProps.icon?.size || new window.google.maps.Size(35, 35)
	}

	return <Marker { ...markerProps } />;
};

GoogleMarker.propTypes = propTypes;

export { GoogleMarker };